import { NgModule } from '@angular/core';
import { OvGenericModule } from '@ov-suite/ui';
import { ProductSku } from '@ov-suite/models-admin';
import { UnitTogglesComponent } from './unit-toggles/unit-toggles.component';

@NgModule({
  imports: [
    OvGenericModule.forRoot({
      entity: ProductSku,
      api: 'adminlink',
      createTitle: 'Product SKU',
      route: ['product-sku'],
      tableTitle: 'Products SKU',
      tabs: [
        { title: 'Product Categories', iconClass: '', path: '/product-category' },
        { title: 'Products', iconClass: '', path: '/product' },
        { title: 'Product SKUs', iconClass: '', path: '/product-sku' },
      ],
      permissionId: 905,
      titleReplace: UnitTogglesComponent,
    }),
  ],
  declarations: [UnitTogglesComponent]
})
export class ProductSkuModule {}
